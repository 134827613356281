import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";

const PACKAGES = "Packages";
const env = JSON.parse(localStorage.getItem("env") as string);
export class PackageAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getList = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(PACKAGES)
      .catch(catchAxiosError);

    return response;
  };
}
