import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import isEmpty from "lodash/isEmpty";

import { ErrorResponse } from "@/services/axios/error";

import { UserAPI } from "@/services/user";
import {
  IUserDTO,
  IUserOptionDTO,
  IUserParameters,
  IUserRoleDTO,
} from "@/models/User";

import { IPagination } from "@/models/Pagination";

const name = "UserModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class UserModule extends VuexModule {
  private data = [] as IUserDTO[];
  private pagination = {} as IPagination;
  private option = [] as IUserOptionDTO[];
  private error = {} as ErrorResponse;
  private roles = [] as IUserRoleDTO[];

  get getData() {
    return this.data;
  }

  get getPagination() {
    return this.pagination;
  }

  get getOption() {
    return this.option;
  }

  get isError() {
    return !isEmpty(this.error);
  }

  get getRoles() {
    return this.roles;
  }

  @Mutation
  getRolesSuccess(data: IUserRoleDTO[]) {
    this.roles = data;
  }

  @Mutation
  getRolesFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  getOptionSuccess(data: IUserOptionDTO[]) {
    this.option = data;
    this.error = {} as ErrorResponse;
  }

  @Mutation
  getOptionFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  getListSuccess(data: any) {
    if (!isEmpty(data)) {
      const { Collection, ...rest } = data;
      this.data = Collection;
      this.pagination = rest;
    }
    this.error = {} as ErrorResponse;
  }

  @Mutation
  getListFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Action({ rawError: true })
  async fetchRoles() {
    const servicesUserAPI = new UserAPI();
    const { data, error } = await servicesUserAPI.getRoles();

    if (error) {
      this.getRolesFailure(error);
      return;
    }

    this.getRolesSuccess(data);
  }

  @Action({ rawError: true })
  async fetchList(params?: IUserParameters) {
    const servicesUserAPI = new UserAPI();
    const { data, error } = await servicesUserAPI.getList(params);

    if (error) {
      this.getListFailure(error);
      return;
    }

    this.getListSuccess(data);
  }

  @Action
  async getSelection() {
    const servicesUserAPI = new UserAPI();
    const { data, error } = await servicesUserAPI.getSelection();

    if (error) {
      this.getOptionFailure(error);
      return;
    }

    this.getOptionSuccess(data);
  }
}

export default getModule(UserModule);
